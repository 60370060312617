/* eslint-disable sonarjs/no-duplicate-string */
import { isNil } from 'lodash-es';

import { NestedTranslationObject } from '@core/types';

export const GOOGLE_CLIENT_ID = import.meta.env?.VITE_APP_GOOGLE_CLIENT_ID;
export const BASE_URL = import.meta.env?.VITE_APP_BASE_URL;
export const SENTRY_ENV = import.meta.env?.VITE_APP_SENTRY_ENV;
export const APP_VERSION = import.meta.env?.VITE_APP_APP_VERSION;
export const QUERY_KEYS = {
  PROJECTS: ['project'],
  LANGUAGES: ['language'],
  TRANSLATIONS: ['translation'],
  TOKENS: ['token'],
};
// Utils
export const readFileContent = async (
  file: File
): Promise<NestedTranslationObject> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      resolve(
        JSON.parse(event?.target?.result as string) as NestedTranslationObject
      );
    });
    reader.addEventListener('error', (event) => {
      reader.abort();
      reject(event);
    });
    reader.readAsText(file);
  });
};

export const isEmptyValue = (value: unknown) => {
  return isNil(value) || value === '';
};

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';

export const PG_ROLE_ASSIGNMENT_KEY = 'PG_ROLE_ASSIGNMENT';
export const PG_USER_KEY = 'PG_USER';
