// React error boundary
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};
class ErrorBoundary extends React.Component<
  Props,
  { hasError: boolean; error?: Error; errorInfo?: React.ErrorInfo }
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error, errorInfo });
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className='flex items-center justify-center w-screen h-screen'>
          <div className='text-center'>
            <h1 className='text-2xl text-slate-600 mb-6'>
              Something went wrong
            </h1>
            <p className=' text-slate-600'>
              <pre className='text-lg text-red-800'>
                {this.state.error && this.state.error.message}
              </pre>
              {/* Stack */}
              <pre className='text-sm text-red-400'>
                {this.state.error && this.state.error.stack}
              </pre>
            </p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
