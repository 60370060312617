/* eslint-disable sonarjs/no-duplicate-string */
import Cookies from 'js-cookie';

import {
  UpdateContentPayload,
  CreateProjectPayload,
  Language,
  Project,
  Token,
  Translation,
  DuplicateTranslation,
  TranslationQueryArgs,
  CreateContentPayload,
} from '@core/types';

import { BASE_URL } from './constants';

const { fetch: originalFetch } = window;

type FetchFunction = typeof originalFetch;

const fetch: FetchFunction = async (...args) => {
  const [resource, config] = args;
  const response = await originalFetch(resource, {
    ...config,
    credentials: 'same-origin',
    headers: { cookie: `PHPSESSID=${Cookies.get('PHPSESSID') as string}` },
  });
  if (response.status === 401) {
    Cookies.remove('PHPSESSID');
    window.location.href = '/login';
  }
  if (!response.ok) {
    throw await response.json();
  }
  return response;
};

window.fetch = fetch;

// Api
export const fetchProjects = async (): Promise<Array<Project>> => {
  const response = await fetch(`${BASE_URL}/api/projects`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
};

export const createProject = async (data: CreateProjectPayload) => {
  const response = await fetch(`${BASE_URL}/api/projects`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
};

export const updateProject = async (data: Partial<Project>, id: string) => {
  const response = await fetch(`${BASE_URL}/api/projects/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
};

export const createToken = async (body: Token) => {
  const response = await fetch(`${BASE_URL}/api/token`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return (await response.json()) as Token;
};

export const createTranslation = async (
  body: CreateContentPayload,
  id: string
) => {
  const response = await fetch(`${BASE_URL}/api/projects/${id}/translations`, {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await response.json();
};
export const duplicateTranslation = async (
  body: DuplicateTranslation,
  id: string
) => {
  const response = await fetch(
    `${BASE_URL}/api/projects/${id}/translations/duplicate`,
    {
      body: JSON.stringify(body),
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return await response.json();
};

export const upsertTranslationContent = async (
  body: UpdateContentPayload,
  projectId: string
) => {
  const response = await fetch(
    `${BASE_URL}/api/projects/${projectId}/translations`,
    {
      body: JSON.stringify(body),
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return await response.json();
};

export const fetchProject = async (id: string): Promise<Project> => {
  const response = await fetch(`${BASE_URL}/api/projects/${id}`);
  return response.json();
};

export const fetchTranslationsByNameSpace = async (
  args: TranslationQueryArgs
): Promise<Translation> => {
  const response = await fetch(
    `${BASE_URL}/api/projects/${args.projectId}/translations/${args.namespace}/${args.locale}`
  );
  return response.json();
};

export const fetchLanguages = async (): Promise<Array<Language>> => {
  const response = await fetch(`${BASE_URL}/api/languages`);
  return response.json();
};

export const fetchToken = async (): Promise<Array<Token>> => {
  const response = await fetch(`${BASE_URL}/api/token`);
  return response.json();
};

export const updateToken = async (token: Token) => {
  const response = await fetch(`${BASE_URL}/api/token/${token.id}`, {
    body: JSON.stringify(token),
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.json();
};

export const deleteToken = async (token: Token) => {
  const response = await fetch(`${BASE_URL}/api/token/${token.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.json();
};

export const startUserSession = (token: string, username: string) => {
  return fetch(`${BASE_URL}/api/auth/start-session`, {
    method: 'POST',
    body: JSON.stringify({ token, username }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const logout = () => {
  return fetch(`${BASE_URL}/api/auth/logout`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getRoleAssignments = async (
  username: string
): Promise<string[]> => {
  const response = await fetch(`${BASE_URL}/api/auth/roles/${username}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return await response.json();
};

export const suggestTranslation = async (
  defaultLanguageValue: string,
  targetLanguage: string
): Promise<{ translatedText: string }> => {
  const response = await fetch(`${BASE_URL}/api/translations/translate`, {
    method: 'POST',
    body: JSON.stringify({
      q: defaultLanguageValue.trim(),
      source: 'auto',
      target: targetLanguage,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

  return await response.json();
};
