import * as React from 'react';

import { clsx } from 'clsx';

type SelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  fullWidth?: boolean;
  options?: Array<{
    value: string;
    label: string;
  }>;
};

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ options = [], placeholder, fullWidth, ...rest }, ref) => {
    return (
      <select
        ref={ref}
        {...rest}
        className={clsx(
          'px-4 py-1.5 text-sm text-gray-900 bg-white border-solid border-gray-300 rounded-lg border',
          {
            'w-full': fullWidth,
          }
        )}
      >
        {placeholder && (
          <option value='' disabled>
            {placeholder}
          </option>
        )}
        {options.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>
    );
  }
);

export default Select;
