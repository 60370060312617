import * as React from 'react';

import { Spinner } from './';

const FullPageSpinner: React.FC = () => {
  return (
    <div className='flex justify-center items-center h-screen w-screen'>
      <Spinner />
    </div>
  );
};

export default FullPageSpinner;
