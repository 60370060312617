import * as Sentry from '@sentry/react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import ErrorBoundary from '@core/components/layouts/error-boundary';

import { SENTRY_ENV } from '@core/utils';

import { AuthProvider } from '@core/contexts';
import { ToasterProvider } from '@core/contexts/toast-context';

import Router from './core/router';
import './core/styles/index.scss';

const queryClient = new QueryClient();

Sentry.init({
  dsn: 'https://7ad57f18728d42b69acf597ff344a69c@o538396.ingest.sentry.io/6731186',

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.5,
  environment: SENTRY_ENV || 'prod',
  ignoreErrors: ['Failed to fetch dynamically imported module'],
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ToasterProvider>
            <Router />
          </ToasterProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
