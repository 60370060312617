import * as React from 'react';

import { Select } from '@core/components/shared';

type SelectProps = React.ComponentProps<typeof Select>;

const LANGUAGES = [
  {
    name: 'Albanian (Albania)',
    id: 'sq_AL',
  },
  {
    name: 'Albanian',
    id: 'sq',
  },
  {
    name: 'Arabic (Algeria)',
    id: 'ar_DZ',
  },
  {
    name: 'Arabic (Bahrain)',
    id: 'ar_BH',
  },
  {
    name: 'Arabic (Egypt)',
    id: 'ar_EG',
  },
  {
    name: 'Arabic (Iraq)',
    id: 'ar_IQ',
  },
  {
    name: 'Arabic (Jordan)',
    id: 'ar_JO',
  },
  {
    name: 'Arabic (Kuwait)',
    id: 'ar_KW',
  },
  {
    name: 'Arabic (Lebanon)',
    id: 'ar_LB',
  },
  {
    name: 'Arabic (Libya)',
    id: 'ar_LY',
  },
  {
    name: 'Arabic (Morocco)',
    id: 'ar_MA',
  },
  {
    name: 'Arabic (Oman)',
    id: 'ar_OM',
  },
  {
    name: 'Arabic (Qatar)',
    id: 'ar_QA',
  },
  {
    name: 'Arabic (Saudi Arabia)',
    id: 'ar_SA',
  },
  {
    name: 'Arabic (Sudan)',
    id: 'ar_SD',
  },
  {
    name: 'Arabic (Syria)',
    id: 'ar_SY',
  },
  {
    name: 'Arabic (Tunisia)',
    id: 'ar_TN',
  },
  {
    name: 'Arabic (United Arab Emirates)',
    id: 'ar_AE',
  },
  {
    name: 'Arabic (Yemen)',
    id: 'ar_YE',
  },
  {
    name: 'Arabic',
    id: 'ar',
  },
  {
    name: 'Belarusian (Belarus)',
    id: 'be_BY',
  },
  {
    name: 'Belarusian',
    id: 'be',
  },
  {
    name: 'Bengali (India)',
    id: 'bn_IN',
  },
  {
    name: 'Bengali (Bangladesh)',
    id: 'bn_BD',
  },
  {
    name: 'Bengali',
    id: 'bn',
  },
  {
    name: 'Bulgarian (Bulgaria)',
    id: 'bg_BG',
  },
  {
    name: 'Bulgarian',
    id: 'bg',
  },
  {
    name: 'Catalan (Spain)',
    id: 'ca_ES',
  },
  {
    name: 'Catalan',
    id: 'ca',
  },
  {
    name: 'Chinese (China)',
    id: 'zh_CN',
  },
  {
    name: 'Chinese (Hong Kong)',
    id: 'zh_HK',
  },
  {
    name: 'Chinese (Singapore)',
    id: 'zh_SG',
  },
  {
    name: 'Chinese (Taiwan)',
    id: 'zh_TW',
  },
  {
    name: 'Chinese',
    id: 'zh',
  },
  {
    name: 'Croatian (Croatia)',
    id: 'hr_HR',
  },
  {
    name: 'Croatian',
    id: 'hr',
  },
  {
    name: 'Czech (Czech Republic)',
    id: 'cs_CZ',
  },
  {
    name: 'Czech',
    id: 'cs',
  },
  {
    name: 'Danish (Denmark)',
    id: 'da_DK',
  },
  {
    name: 'Danish',
    id: 'da',
  },
  {
    name: 'Dutch (Belgium)',
    id: 'nl_BE',
  },
  {
    name: 'Dutch (Netherlands)',
    id: 'nl_NL',
  },
  {
    name: 'Dutch',
    id: 'nl',
  },
  {
    name: 'English (Australia)',
    id: 'en_AU',
  },
  {
    name: 'English (Canada)',
    id: 'en_CA',
  },
  {
    name: 'English (India)',
    id: 'en_IN',
  },
  {
    name: 'English (Ireland)',
    id: 'en_IE',
  },
  {
    name: 'English (Malta)',
    id: 'en_MT',
  },
  {
    name: 'English (New Zealand)',
    id: 'en_NZ',
  },
  {
    name: 'English (Philippines)',
    id: 'en_PH',
  },
  {
    name: 'English (Singapore)',
    id: 'en_SG',
  },
  {
    name: 'English (South Africa)',
    id: 'en_ZA',
  },
  {
    name: 'English (United Kingdom)',
    id: 'en_GB',
  },
  {
    name: 'English (United States)',
    id: 'en_US',
  },
  {
    name: 'English',
    id: 'en',
  },
  {
    name: 'Estonian (Estonia)',
    id: 'et_EE',
  },
  {
    name: 'Estonian',
    id: 'et',
  },
  {
    name: 'Finnish (Finland)',
    id: 'fi_FI',
  },
  {
    name: 'Finnish',
    id: 'fi',
  },
  {
    name: 'French (Belgium)',
    id: 'fr_BE',
  },
  {
    name: 'French (Canada)',
    id: 'fr_CA',
  },
  {
    name: 'French (France)',
    id: 'fr_FR',
  },
  {
    name: 'French (Luxembourg)',
    id: 'fr_LU',
  },
  {
    name: 'French (Switzerland)',
    id: 'fr_CH',
  },
  {
    name: 'French',
    id: 'fr',
  },
  {
    name: 'German (Austria)',
    id: 'de_AT',
  },
  {
    name: 'German (Germany)',
    id: 'de_DE',
  },
  {
    name: 'German (Luxembourg)',
    id: 'de_LU',
  },
  {
    name: 'German (Switzerland)',
    id: 'de_CH',
  },
  {
    name: 'German',
    id: 'de',
  },
  {
    name: 'Greek (Cyprus)',
    id: 'el_CY',
  },
  {
    name: 'Greek (Greece)',
    id: 'el_GR',
  },
  {
    name: 'Greek',
    id: 'el',
  },
  {
    name: 'Hebrew (Israel)',
    id: 'iw_IL',
  },
  {
    name: 'Hebrew',
    id: 'iw',
  },
  {
    name: 'Hindi (India)',
    id: 'hi_IN',
  },
  {
    name: 'Hungarian (Hungary)',
    id: 'hu_HU',
  },
  {
    name: 'Hungarian',
    id: 'hu',
  },
  {
    name: 'Icelandic (Iceland)',
    id: 'is_IS',
  },
  {
    name: 'Icelandic',
    id: 'is',
  },
  {
    name: 'Indonesian (Indonesia)',
    id: 'in_ID',
  },
  {
    name: 'Indonesian',
    id: 'in',
  },
  {
    name: 'Irish (Ireland)',
    id: 'ga_IE',
  },
  {
    name: 'Irish',
    id: 'ga',
  },
  {
    name: 'Italian (Italy)',
    id: 'it_IT',
  },
  {
    name: 'Italian (Switzerland)',
    id: 'it_CH',
  },
  {
    name: 'Italian',
    id: 'it',
  },
  {
    name: 'Japanese (Japan)',
    id: 'ja_JP',
  },
  {
    name: 'Japanese (Japan,JP)',
    id: 'ja_JP_JP',
  },
  {
    name: 'Japanese',
    id: 'ja',
  },
  {
    name: 'Korean (South Korea)',
    id: 'ko_KR',
  },
  {
    name: 'Korean',
    id: 'ko',
  },
  {
    name: 'Latvian (Latvia)',
    id: 'lv_LV',
  },
  {
    name: 'Latvian',
    id: 'lv',
  },
  {
    name: 'Lithuanian (Lithuania)',
    id: 'lt_LT',
  },
  {
    name: 'Lithuanian',
    id: 'lt',
  },
  {
    name: 'Macedonian (Macedonia)',
    id: 'mk_MK',
  },
  {
    name: 'Macedonian',
    id: 'mk',
  },
  {
    name: 'Malay (Malaysia)',
    id: 'ms_MY',
  },
  {
    name: 'Malay',
    id: 'ms',
  },
  {
    name: 'Maltese (Malta)',
    id: 'mt_MT',
  },
  {
    name: 'Maltese',
    id: 'mt',
  },
  {
    name: 'Norwegian (Norway)',
    id: 'no_NO',
  },
  {
    name: 'Norwegian (Norway,Nynorsk)',
    id: 'no_NO_NY',
  },
  {
    name: 'Norwegian',
    id: 'no',
  },
  {
    name: 'Polish (Poland)',
    id: 'pl_PL',
  },
  {
    name: 'Polish',
    id: 'pl',
  },
  {
    name: 'Portuguese (Brazil)',
    id: 'pt_BR',
  },
  {
    name: 'Portuguese (Portugal)',
    id: 'pt_PT',
  },
  {
    name: 'Portuguese',
    id: 'pt',
  },
  {
    name: 'Romanian (Romania)',
    id: 'ro_RO',
  },
  {
    name: 'Romanian',
    id: 'ro',
  },
  {
    name: 'Russian (Russia)',
    id: 'ru_RU',
  },
  {
    name: 'Russian',
    id: 'ru',
  },
  {
    name: 'Serbian (Bosnia and Herzegovina)',
    id: 'sr_BA',
  },
  {
    name: 'Serbian (Montenegro)',
    id: 'sr_ME',
  },
  {
    name: 'Serbian (Serbia and Montenegro)',
    id: 'sr_CS',
  },
  {
    name: 'Serbian (Serbia)',
    id: 'sr_RS',
  },
  {
    name: 'Serbian',
    id: 'sr',
  },
  {
    name: 'Slovak (Slovakia)',
    id: 'sk_SK',
  },
  {
    name: 'Slovak',
    id: 'sk',
  },
  {
    name: 'Slovenian (Slovenia)',
    id: 'sl_SI',
  },
  {
    name: 'Slovenian',
    id: 'sl',
  },
  {
    name: 'Spanish (Argentina)',
    id: 'es_AR',
  },
  {
    name: 'Spanish (Bolivia)',
    id: 'es_BO',
  },
  {
    name: 'Spanish (Chile)',
    id: 'es_CL',
  },
  {
    name: 'Spanish (Colombia)',
    id: 'es_CO',
  },
  {
    name: 'Spanish (Costa Rica)',
    id: 'es_CR',
  },
  {
    name: 'Spanish (Dominican Republic)',
    id: 'es_DO',
  },
  {
    name: 'Spanish (Ecuador)',
    id: 'es_EC',
  },
  {
    name: 'Spanish (El Salvador)',
    id: 'es_SV',
  },
  {
    name: 'Spanish (Guatemala)',
    id: 'es_GT',
  },
  {
    name: 'Spanish (Honduras)',
    id: 'es_HN',
  },
  {
    name: 'Spanish (Mexico)',
    id: 'es_MX',
  },
  {
    name: 'Spanish (Nicaragua)',
    id: 'es_NI',
  },
  {
    name: 'Spanish (Panama)',
    id: 'es_PA',
  },
  {
    name: 'Spanish (Paraguay)',
    id: 'es_PY',
  },
  {
    name: 'Spanish (Peru)',
    id: 'es_PE',
  },
  {
    name: 'Spanish (Puerto Rico)',
    id: 'es_PR',
  },
  {
    name: 'Spanish (Spain)',
    id: 'es_ES',
  },
  {
    name: 'Spanish (United States)',
    id: 'es_US',
  },
  {
    name: 'Spanish (Uruguay)',
    id: 'es_UY',
  },
  {
    name: 'Spanish (Venezuela)',
    id: 'es_VE',
  },
  {
    name: 'Spanish',
    id: 'es',
  },
  {
    name: 'Swedish (Sweden)',
    id: 'sv_SE',
  },
  {
    name: 'Swedish',
    id: 'sv',
  },
  {
    name: 'Thai (Thailand)',
    id: 'th_TH',
  },
  {
    name: 'Thai (Thailand,TH)',
    id: 'th_TH_TH',
  },
  {
    name: 'Thai',
    id: 'th',
  },
  {
    name: 'Turkish (Turkey)',
    id: 'tr_TR',
  },
  {
    name: 'Turkish',
    id: 'tr',
  },
  {
    name: 'Ukrainian (Ukraine)',
    id: 'uk_UA',
  },
  {
    name: 'Ukrainian',
    id: 'uk',
  },
  {
    name: 'Vietnamese (Vietnam)',
    id: 'vi_VN',
  },
  {
    name: 'Vietnamese',
    id: 'vi',
  },
];
const LanguageSelect = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ ...rest }, ref) => {
    const options =
      rest.options ||
      LANGUAGES.map((language) => ({
        value: language.id,
        label: `${language.name} [${language.id}]`,
      }));
    return (
      <Select
        ref={ref}
        {...rest}
        options={options}
        placeholder='Select language'
      />
    );
  }
);

export default LanguageSelect;
