import Cookies from 'js-cookie';

import * as React from 'react';
import { GoogleLoginResponse } from 'react-google-login';

import {
  getRoleAssignments,
  logout,
  PG_ROLE_ASSIGNMENT_KEY,
  PG_USER_KEY,
  startUserSession,
} from '@core/utils';

export interface GoogleUser {
  id: string;
  email: string;
  name: string;
  givenName: string;
  familyName: string;
  imageUrl: string;
  jwtToken: string;
}

export interface IUserContext {
  login: (googleResponse: GoogleLoginResponse) => void;
  logout: () => void;
  getRoleAssignment: () => Array<string>;
  sessionId?: string;
  roleAssignment?: Array<string>;
}

const INITIAL_CONTEXT: IUserContext = {
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  getRoleAssignment: () => [],
  sessionId: Cookies.get('PHPSESSID'),
  roleAssignment: [],
};

const AuthContext = React.createContext<IUserContext>(INITIAL_CONTEXT);

const useAuthHandlers = () => {
  const [state, setState] = React.useState<IUserContext>(INITIAL_CONTEXT);
  const handlers: IUserContext = React.useMemo(
    () => ({
      login: (googleResponse) => {
        return startUserSession(
          googleResponse.getAuthResponse().id_token,
          googleResponse.getBasicProfile().getEmail()
        )
          .then(() => {
            Cookies.set(
              'PG_USER',
              JSON.stringify({
                id: googleResponse.getBasicProfile().getId(),
                email: googleResponse.getBasicProfile().getEmail(),
                givenName: googleResponse.getBasicProfile().getGivenName(),
                familyName: googleResponse.getBasicProfile().getFamilyName(),
                imageUrl: googleResponse.getBasicProfile().getImageUrl(),
              })
            );
            setState((prevState) => ({
              ...prevState,
              sessionId: Cookies.get('PHPSESSID'),
            }));
          })
          .then(() =>
            getRoleAssignments(
              googleResponse.getBasicProfile().getEmail()
            ).then((roleAssignment) => {
              Cookies.set('PG_ROLE_ASSIGNMENT', JSON.stringify(roleAssignment));
              setState((prevState) => ({
                ...prevState,
                roleAssignment,
              }));
            })
          );
      },
      getRoleAssignment: () => {
        const roleAssignment = Cookies.get(PG_ROLE_ASSIGNMENT_KEY);
        const userData = Cookies.get(PG_USER_KEY);

        if (undefined === roleAssignment) {
          if (userData) {
            const user = JSON.parse(userData);
            getRoleAssignments(user.email).then((roleAssignment) => {
              Cookies.set('PG_ROLE_ASSIGNMENT', JSON.stringify(roleAssignment));
            });
          }
        }

        return JSON.parse(roleAssignment as string);
      },
      logout: () => {
        logout().catch(() => {});
        Cookies.remove('PHPSESSID');
        Cookies.remove(PG_USER_KEY);
        Cookies.remove(PG_ROLE_ASSIGNMENT_KEY);
      },
    }),
    []
  );

  return { handlers, state };
};

function useAuth(): IUserContext {
  return React.useContext(AuthContext);
}

const AuthProvider: React.FC = ({ children }) => {
  const { handlers, state } = useAuthHandlers();
  const context = React.useMemo(
    () => ({ ...state, ...handlers }),
    [handlers, state]
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
