import * as React from 'react';

type FileProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  helpText?: string;
  error?: string;
};

const FileInput = React.forwardRef<HTMLInputElement, FileProps>(
  (
    {
      label,
      helpText = 'Upload or drag & drop your file JSON.',
      error,
      ...rest
    },
    ref
  ) => {
    return (
      <label className='cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed border-blue-400 bg-white p-6 text-center'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-10 w-10 text-blue-500'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
          strokeWidth='2'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
          />
        </svg>

        <h2 className='text-xl font-medium text-gray-700 tracking-wide'>
          {label}
        </h2>

        <p className='text-gray-500 tracking-wide'>{helpText}</p>

        <input ref={ref} type='file' accept='application/JSON' {...rest} />
        {error && <div className='text-red-500 text-xs'>{error}</div>}
      </label>
    );
  }
);

export default FileInput;
