import * as React from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

import FullPageSpinner from '@core/components/shared/full-page-spinner';

import { useAuth } from '@core/contexts';

const AuthenticatedApp = React.lazy(() => import('./authenticated-app'));
const UnAuthenticatedApp = React.lazy(() => import('./unauthenticated-app'));

const App: React.FC = () => {
  const { sessionId } = useAuth();
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <Router>
        <Routes>
          <Route
            path='*'
            element={sessionId ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
          ></Route>
        </Routes>
      </Router>
    </React.Suspense>
  );
};

export default App;
