import 'toastr/build/toastr.min.css';

import * as React from 'react';

import toastr from 'toastr';

export enum IToasterEnum {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

interface IToasterState {
  title?: string;
  message: string;
  // position?: SnackbarOrigin;
  open?: boolean;
  type?: IToasterEnum;
}

export type IToasterDispatcher = (notif: IToasterState) => void;

interface AlertMapping {
  [key: string]: IToasterEnum;
}

export const ALERT_MAPPING: AlertMapping = {
  idle: IToasterEnum.Info,
  loading: IToasterEnum.Info,
  success: IToasterEnum.Success,
  error: IToasterEnum.Error,
};

const ToasterContext = React.createContext<IToasterDispatcher>(() => {});
const ToasterProvider: React.FC = ({ children, ...rest }) => {
  const notify = React.useCallback((notification: IToasterState) => {
    toastr.options = {
      positionClass: 'toast-top-right',
      timeOut: 5000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: false,
      newestOnTop: true,
      showEasing: 'linear',
      hideEasing: 'linear',
      showMethod: 'show',
      hideMethod: 'hide',
      showDuration: 300,
      hideDuration: 1000,
    };

    //Toaster Types
    switch (notification.type) {
      case IToasterEnum.Info: {
        toastr.info(notification.message, notification.title);
        break;
      }
      case IToasterEnum.Warning: {
        toastr.warning(notification.message, notification.title);
        break;
      }
      case IToasterEnum.Error: {
        toastr.error(notification.message, notification.title);
        break;
      }
      default:
        toastr.success(notification.message, notification.title);
    }
  }, []);

  return (
    <ToasterContext.Provider value={notify} {...rest}>
      {children}
    </ToasterContext.Provider>
  );
};

function useToaster(): IToasterDispatcher {
  const context = React.useContext(ToasterContext);
  if (context === undefined) {
    throw new Error('useToaster must be used within a ToasterProvider');
  }
  return context;
}

export { ToasterProvider, ToasterContext, useToaster };
