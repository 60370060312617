import * as React from 'react';

import { clsx } from 'clsx';

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  variant?: 'primary' | 'secondary';
};
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ ...rest }, ref) => {
    return (
      <input
        ref={ref}
        {...rest}
        className={clsx(
          `w-full px-4 py-1.5 bg-white placeholder-slate-400 border border-solid  rounded-md sm:text-sm text-black `,
          {
            'border border-slate-300': !rest.readOnly,
            'border-0': rest.readOnly,
          }
        )}
      />
    );
  }
);

export default Input;
